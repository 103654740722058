// 正式环境
// module.exports = {
//     region: "oss-cn-beijing",
//     accessKeyId: "LTAI5tAL6tEvbJoLBVgo5eZ2",
//     accessKeySecret: "3T5r7aKywJc6kiEgzNBa9NGMhEnO8x",
//     // bucket: defaultConfig._Bucket, // online
//     bucket: "q-res-online", // test
//     publicPath: "https://r2-online.qingwashipin.com/qingwa-index/",
//     baseURI:"https://issue.qingwashipin.com/"
// };

// 开发环境
module.exports = {
    region: "oss-cn-beijing",
    accessKeyId: "LTAI5tAL6tEvbJoLBVgo5eZ2",
    accessKeySecret: "3T5r7aKywJc6kiEgzNBa9NGMhEnO8x",
    // bucket: defaultConfig._Bucket, // online
    bucket: "q-res-dev", // test
    publicPath: "https://r2-dev.qingwashipin.com/qingwa-index/",
    baseURI:"https://issue-dev.qingwashipin.com/"
};

// 测试环境

// module.exports = {
//   region: "oss-cn-beijing",
//   accessKeyId: "LTAI5tAL6tEvbJoLBVgo5eZ2",
//   accessKeySecret: "3T5r7aKywJc6kiEgzNBa9NGMhEnO8x",
//   // bucket: defaultConfig._Bucket, // online
//   bucket: "q-res", // test
//   publicPath:"https://r2.qingwashipin.com/qingwa-index/",
//   baseURI:"https://issue-test.qingwashipin.com/"
// };