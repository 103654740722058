import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import "lib-flexible";
import 'babel-polyfill'

import promise from 'es6-promise'

promise.polyfill()
import VueAwesomeSwiper from "vue-awesome-swiper";
// import style (<= Swiper 5.x)swiper5的css
import "swiper/css/swiper.css";
import { Upload, Button, Switch, Icon } from "ant-design-vue"
// import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(Upload).use(Button).use(Switch).use(Icon);
Vue.use(ElementUI);
import defaultConfig from "../oss.config";
axios.defaults.baseURL = defaultConfig.baseURL;
Vue.prototype.$axios = axios;
new Vue({
  router,

  render: function (createElement) {

    if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {

      window.addEventListener('hashchange', () => {

        var currentPath = window.location.hash.slice(1)

        if (this.$route.path !== currentPath) {

          this.$router.push(currentPath)

        }

      }, false)

    }

    return createElement(App);

  }
}).$mount("#app");
