






























































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/css/swiper.css"

@Component({
    components: {
        Swiper,
        SwiperSlide,
    },
})
export default class HelloWorld extends Vue {
    $router
    @Prop() private msg!: string;
    brands: Array<any> = [
        "https://r2.qingwashipin.com/qingwa-index/11.png",
        "https://r2.qingwashipin.com/qingwa-index/22.png",
        "https://r2.qingwashipin.com/qingwa-index/33.png",
        "https://r2.qingwashipin.com/qingwa-index/44.png",
    ];
    swiperOptionThumbs = {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 0,
        centeredSlides: true,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        slidesPerView: 10,
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
        },
    };

    goback(num) {
        if (num == 0) {
            this.$router.push({path: '/copyrightJoin'})
        } else {
            this.$router.push({path: '/channel'})
        }
    };

    goPage(data) {
        let routeData;
        let base = 'https://issue-test.qingwashipin.com/#'
        if (data === 'problem') {
            // routeData = this.$router.resolve({path: '/problem'});
            routeData = '/problem';
        }
        if (data === 'agreement') {
            // routeData = this.$router.resolve({path: '/agreement'});
            routeData = '/agreement';
        }

        if (data === 'privacy') {
            // routeData = this.$router.resolve({path: '/privacy'});
            routeData = '/privacy';
        }

        if (data === 'settlement') {
            routeData = '/settlement';
        }

        if (data === 'introduce') {
            routeData = '/introduce';
        }

        if (data === 'contact') {
            routeData = '/contact';
        }
        window.open(base + routeData,'_blank')
    }
}
